import React from 'react';
import Title from '../layouts/Title';
import { projectOne, projectThree } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section id="projects" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title des="Projects" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Hack The Box"
          src={projectOne}
          url="https://hackthebox.eu"
        />
        <ProjectsCard
          title="Try Hack Me"
          src={projectThree}
          url="https://tryhackme.com/p/Dhruvil307"
        />
      </div>
    </section>
  );
}

export default Projects;
