import { AiFillSecurityScan } from "react-icons/ai";
import { HiCollection } from "react-icons/hi";
import { FaFingerprint } from "react-icons/fa";

// Features Data
export const featuresData = [
  {
    id: 1,
    icon: <FaFingerprint />,
    title: "Ethical Hacking",
    des: "Active participant in cybersecurity challenges and platforms like TryHackMe. Continuously improving skills in penetration testing and vulnerability assessment.",
  },
  {
    id: 2,
    icon: <HiCollection />,
    title: "Cybersecurity Projects",
    des: "Contributed as a contractor in various IT projects, focusing on diagnosing and resolving security issues, ensuring robust protection measures for digital systems.",
  },
  {
    id: 3,
    icon: <AiFillSecurityScan />,
    title: "Cybersecurity Tools",
    des: "Proficient in using cybersecurity tools and techniques to defend against threats and secure digital environments effectively.",
  },
];
