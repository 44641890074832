import React from 'react'
import { motion } from 'framer-motion';
import ResumeCard from './ResumeCard';

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2009 - 2026</p>
          <h2 className="text-3xl md:text-4xl font-bold">Education</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
        <ResumeCard
          title="Bachelors in CyberSecurity"
          subTitle="Sheridan College (2022 - 2026)"
          result="3.51/4"
          des="Studied comprehensive cybersecurity principles and practices, equipping me with the skills necessary for roles across various technology sectors."
        />
        <ResumeCard
          title="High School"
          subTitle="Best High School (2019 - 2021)"
          result="Grade B : 75%"
          des="Focused on scientific disciplines, providing practical insights and foundational knowledge crucial for academic journey."
        />
        <ResumeCard
          title="Secondary School"
          subTitle="Best Higher Secondary School (2009 - 2019)"
          result="Grade A"
          des="Laid the groundwork for academic pursuits, establishing a strong foundation in fundamental subjects."
        />

        </div>
      </div>
      {/* part Two */}

      {/* <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2022 - currently</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Team Trainer"
            subTitle="Chuck E Cheese - (2022 - Present)"
            result="Canada"
            des="As being part of this company, it provide me the skillset of associate."
          />
          {/* <ResumeCard
            title="NA"
            subTitle="NA"
            result="NA"
            des="NA"
          /> */}
          {/* <ResumeCard
            title="Technician contractor"
            subTitle="Netflix - (2023 - 2023)"
            result="Canada"
            des="Role was to install the cables, curved monitors and other essential features for a lab setup."
          />
        </div>
      </div> */}
    </motion.div>
  );
}

export default Education
