import React from "react";
import {motion} from "framer-motion"
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex gap-20"
    >
      <div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2010 - current</p>
          <h2 className="text-4xl font-bold">Experience</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
        <ResumeCard
          title="Contract Technician"
          subTitle="TITEC Modern IT Solutions (2022 - Present)"
          result="Canada"
          des="Contributed as a key team member in various IT projects, demonstrating proficiency in troubleshooting and problem-solving. Collaborated effectively to implement solutions and optimize system performance."
        />
          {/* <ResumeCard
            title="NA"
            subTitle="NA"
            result="NA"
            des="NA"
          /> */}
          
        </div>
      </div>
      {/* <div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2001 - 2020</p>
          <h2 className="text-4xl font-bold">Trainer Experience</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="NA"
            subTitle="NA"
            result="NA"
            des="NA"
          />
          <ResumeCard
            title="NA"
            subTitle="NA"
            result="NA"
            des="NA"
          />
          <ResumeCard
            title="NA"
            subTitle="NA"
            result="NA"
            des="NA"
          />
        </div>
      </div> */}
    </motion.div>
  );
};

export default Experience;
