import React, { useState } from 'react';
import { FaFacebookF, FaGithub, FaLinkedinIn } from 'react-icons/fa';
import { logo } from '../../assets/index';

const Footer = () => {
  const [content, setContent] = useState('');

  const handleItemClick = (itemName) => {
    switch (itemName) {
      case 'About':
        
        window.scrollTo({
          top: 0,
          behavior: 'smooth' 
        });
        break;
      case 'Services':
        setContent('Description of services provided.');
        break;
      case 'Blog':
        
        alert('Blog is currently work in progress.');
        break;
      default:
        setContent('');
        break;
    }
  };

  return (
    <div className="w-full py-20 h-auto border-b-[1px] border-b-black grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-4 gap-8">
      <div className="w-full h-full flex flex-col gap-8">
        <img className="w-32" src={logo} alt="logo" />
        <div className="flex gap-4">
          <span className="bannerIcon">
            <a href="https://m.facebook.com/dhruvil.patel.1428/" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
          </span>
          <span className="bannerIcon">
            <a href="https://github.com/Dhruvil307/" target="_blank" rel="noopener noreferrer">
              <FaGithub />
            </a>
          </span>
          <span className="bannerIcon">
            <a href="https://www.linkedin.com/in/dhruvil-patel-69b797251/" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
          </span>
        </div>
      </div>
      <div className="w-full h-full">
        <h3 className="text-xl uppercase text-designColor tracking-wider">
          Quick Link
        </h3>
        <ul className="flex flex-col gap-4 font-titleFont font-medium py-6 overflow-hidden">
          <li>
            <span
              className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer"
              onClick={() => handleItemClick('About')}
            >
              About
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
          <li>
            <span
              className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer"
              onClick={() => handleItemClick('Services')}
            >
              Services
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
          <li>
            <span
              className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer"
              onClick={() => handleItemClick('Blog')}
            >
              Blog
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
