import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Media from './Media';

const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Contract Technician", "CyberSecurity Researcher"],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h1 className="text-6xl font-bold text-white">
          Hello, I'm <span className="text-designColor capitalize">Dhruvil Patel</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          As a cybersecurity professional, I am deeply passionate about continuously advancing my knowledge and skills in securing digital environments. I actively engage in hands-on learning through platforms like Hack The Box, where I solve challenges and explore cutting-edge cybersecurity techniques. Additionally, I leverage specialized academy sections to deepen my understanding of threat landscapes, ethical hacking methodologies, and defensive strategies. My enthusiasm for cybersecurity extends beyond personal development; I am dedicated to contributing my expertise to safeguarding digital infrastructures and fostering a secure online ecosystem.
        </p>
      </div>
     {/* Media */}
     <Media />
    </div>
  );
}

export default LeftBanner
